import React from "react";
import TimelessLogo from '../assets/img/white-full-logo.png'
import Flowers from '../assets/img/flowers.png'

const Footer = () => {
    return(
        <div className="my-footer">
            <div className='ready'>
				<div className='content-container'>
					<div className='text-side'>
						<h1>Are You <span>Ready to Glow?</span></h1>
                        <p>Experience beauty in full bloom at our serene oasis. Let our expert team pamper you to perfection among fragrant blossoms. Book your appointment now.</p>
                        <a href="/book-appointment" className="main-btn">Book Now</a>
					</div>
					<img src={Flowers} alt="flowers" className="flowers-img"/>
				</div>
			</div>
            <div className="brand-part">
                <div className="row justify-content-between align-items-center">
                    <div className="col-sm-4">
                        <img src={TimelessLogo} alt="Timeless Glam Haven Logo" />
                    </div>
                    <div className="col-sm-4 footer-about">
                        <h4>About Us</h4>
                        <p>At Timeless Glam Haven, beauty transcends time. Our sanctuary of elegance offers tailored perfection. Luxurious treatments, serene ambience, expert pampering – your inner radiance revealed.</p>
                    </div>
                </div>
            </div>
            <div className="links-part">
                <div className="row gy-4">
                    <div className="col-sm-3">
                        <h4>Quick Links</h4>
                        <ul className="list-unstyled">
                            <li><a href="/services">Services</a></li>
                            <li><a href="/book-appointment">Book Appointment</a></li>
                            <li><a href="/gallery">Gallery</a></li>
                            <li><a href="/contact">Contact</a></li>
                        </ul>
                    </div>
                    <div className="col-sm-3">
                        <h4>Business Hours</h4>
                        <ul className="list-unstyled">
                            <li>
                                <span className="color-primary">Mon - Fri</span>
                                <p>8:30am - 6:30pm</p>
                            </li>
                            <li>
                                <span className="color-primary">Sat</span>
                                <p>8:30am - 6:30pm</p>
                            </li>
                            <li>
                                <span className="color-primary">Sun</span>
                                <p>9:00am - 5:00pm</p>
                            </li>
                        </ul>
                    </div>
                    <div className="col-sm-3">
                        <h4>Contact</h4>
                        <ul className="list-unstyled contact-list">
                            <li>
                                <span className="color-primary"><i class="bi bi-telephone-fill"></i></span>
                                <a href="tel:0789272904">0789272904</a>
                            </li>
                            <li>
                                <span className="color-primary"><i class="bi bi-envelope-fill"></i></span>
                                <a href="mailto:info@timelessglam.co.za">info@timelessglam.co.za</a>
                            </li>
                            <li>
                                <span className="color-primary"><i class="bi bi-geo-alt-fill"></i></span>
                                <span>84 Dr AB Xuma, Durban central, Durban 4001</span>
                            </li>
                        </ul>
                    </div>
                    <div className="col-sm-3">
                        <h4>Follow Us</h4>
                        <div className="social-accounts">
                            <a href="https://www.facebook.com/profile.php?id=61556208374191&mibextid=WC7FNe"><i class="bi bi-facebook"></i></a>
                            <a href="https://www.tiktok.com/@timelessglamhaven"><i class="bi bi-tiktok"></i></a>
                            <a href="https://www.instagram.com/timelessglamhaven"><i class="bi bi-instagram"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copyright">
                <p>&copy; {(new Date().getFullYear())} All rights reserved. Designed by <a href="http://www.myplusplus.com" target="_blank" rel="noopener noreferrer">My plus Plus</a>.</p>
            </div>
        </div>
    )
}

export default Footer;
import React, { useState } from "react";

const SearchableSelect = ({
  options,
  selectedServiceType,
  setSelectedServiceType,
}) => {
  const [filter, setFilter] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [noResults, setNoResults] = useState(false);

  const handleInputChange = (e) => {
    setFilter(e.target.value);
    setNoResults(false);
  };

  const handleOptionClick = (option) => {
    setSelectedServiceType(option);
    setIsOpen(false);
    setFilter(option.name);
  };

  const noOptionsMessage = () => {
    if (
      filter &&
      !options.some((category) =>
        category.options.some((option) =>
          option.name.toLowerCase().includes(filter.toLowerCase())
        )
      )
    ) {
      return (
        <div className="no-options">No options available for "{filter}"</div>
      );
    }
    return null;
  };

  return (
    <div className="searchable-select">
      <div className="input-container">
        <input
          type="text"
          value={filter}
          onChange={handleInputChange}
          onFocus={() => setIsOpen(true)}
          placeholder="Search..."
          className="search-box"
        />
        <div className="dropdown-icon" onClick={() => setIsOpen(!isOpen)}>
          ▼
        </div>
      </div>
      {isOpen && (
        <div className="dropdown-content shadow">
          {noOptionsMessage()}
          {options.map((category, index) => (
            <React.Fragment key={index}>
              <div className="category">{category.name}</div>
              {category.options
                .filter((option) =>
                  option.name.toLowerCase().includes(filter.toLowerCase())
                )
                .map((option, index) => (
                  <div
                    key={index}
                    onClick={() => handleOptionClick(option)}
                    className={
                      option === selectedServiceType
                        ? "option selected"
                        : "option"
                    }
                  >
                    {option.name}
                  </div>
                ))}
            </React.Fragment>
          ))}
        </div>
      )}
    </div>
  );
};

export default SearchableSelect;

import React, { useEffect, useState, useRef } from "react";
import axios from "axios";

import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import DateTimeSlotPicker from "../components/DateTimeSlotPicker";
import $ from "jquery";
import SearchableSelect from "../components/SearchableSelect";

const Booking = () => {
  const [fullName, setFullName] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [email, setEmail] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [selectedServiceType, setSelectedServiceType] = useState("");
  const [specialInstruction, setSpecialInstruction] = useState("");
  const [dateTime, setDateTime] = useState("20 February 2024, 5:30 PM");
  const [styles, setStyles] = useState(Array(1).fill(""));

  const handleFullNameChange = (e) => {
    setFullName(e.target.value);
  };

  const handleContactNumberChange = (e) => {
    setContactNumber(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSpecialInstructionChange = (e) => {
    setSpecialInstruction(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log({
      fullName,
      contactNumber,
      email,
      selectedServiceType: selectedServiceType.name,
      specialInstruction,
      selectedDate,
      selectedTimeSlot,
    });

    try {
      const response = await axios.post(
        "https://5jy4uwzziodckwuwr3sopmxcn40aujiw.lambda-url.us-east-1.on.aws/",
        {
          fullName,
          contactNumber,
          email,
          selectedServiceType: selectedServiceType.name,
          specialInstruction,
          selectedDate,
          selectedTimeSlot,
        }
      );
      alert("Email sent successfully");
    } catch (error) {
      console.error("Error sending email:", error);
      alert("Error sending email");
    }
  };

  const unavailableSlots = [
    "2024-02-23T09:00:00",
    "2024-02-23T11:00:00",
    "2024-02-23T16:00:00",
  ];

  const options = [
    {
        "name": "Nails",
        "options": [
            {"name": "Manicure"},
            {"name": "Pedicure"}
        ]
    },
    {
        "name": "Eyelashes",
        "options": [
            { "name": "Eyelash extensions" },
            { "name": "Removal" },
            { "name": "Refill" },
            { "name": "Volume" },
            { "name": "Hybrid" },
            { "name": "Classic" },
            { "name": "Doll eye vs Cat eye" }
        ]
    },
    {
        "name": "Hair",
        "options": [
            {"name": "Relaxed hair care"},
            {"name": "Afro Hair Care"},
            {"name": "Braids care"},
            {"name": "Braid style"}
        ]
    }
  ];

  const selectRef = useRef(null);

  useEffect(() => {
    if (selectRef.current) {
      $(selectRef.current).selectpicker();
    }
  }, []);

  return (
    <div id="booking">
      <Navigation classStyle="in-page-nav" />
      <div className="booking-content">
        <div className="booking-page-cap">
          <h2>Book Appointment</h2>
          <p>
            Transform your look and elevate your style with our exquisite nail,
            eyelash, and hair services. From luxurious manicures and pedicures
            to stunning eyelash enhancements and flawless hair styling, Timeless
            Glam Haven is your ultimate destination for beauty indulgence. Step
            into our haven and let our expert team pamper you to perfection.
            Don't wait any longer to reveal your true beauty - schedule your
            appointment now and experience the magic of Timeless Glam Haven.
          </p>
        </div>
        <form>
          <div className="row gy-3">
            <div className="col-md-8">
              <label htmlFor="name">Full Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={fullName}
                onChange={handleFullNameChange}
                placeholder="Full name (e.g. John Doe)"
              />
            </div>
            <div className="col-md-8">
              <label htmlFor="phone">Contact Number</label>
              <input
                type="number"
                id="phone"
                name="phone"
                value={contactNumber}
                onChange={handleContactNumberChange}
                placeholder="Phone (e.g. 0681825332)"
                required
              />
            </div>
            <div className="col-md-8">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={handleEmailChange}
                placeholder="Email"
              />
            </div>

            <div className="col-sm-8">
              <label htmlFor="style">Select style:</label>
              <SearchableSelect
                options={options}
                selectedServiceType={selectedServiceType}
                setSelectedServiceType={setSelectedServiceType}
              />
            </div>

            <div className="col-sm-8">
              <label htmlFor="datepicker">Select Date</label>
              <DateTimeSlotPicker
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                selectedTimeSlot={selectedTimeSlot}
                setSelectedTimeSlot={setSelectedTimeSlot}
                unavailableSlots={unavailableSlots}
              />
            </div>
            <div className="col-md-8">
              <label htmlFor="instruction">Special Instructions</label>
              <textarea
                name="instruction"
                id="instruction"
                value={specialInstruction}
                onChange={handleSpecialInstructionChange}
                placeholder="Special Instruction"
              ></textarea>
            </div>
            <div className="col-md-8">
              <button type="button" className="main-btn" onClick={handleSubmit}>
                Submit Booking
              </button>
            </div>
          </div>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default Booking;

import React, { useState } from "react";
import axios from "axios";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import TikTokIcon from "../assets/img/tiktok.svg";

const Contact = () => {
  const [event, setEvent] = useState({
    fullName: "",
    email: "",
    contactNumber: "",
    message: "",
  });

  const handleChange = (e) => {
    setEvent({ ...event, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission
    console.log(event);
    try {
      const response = await axios.post(
        "https://crcw7s2mxvzio3bqutjnja4uoa0vllkc.lambda-url.us-east-1.on.aws/",
        event
      );
      alert("Email sent successfully");
    } catch (error) {
      console.error("Error sending email:", error);
      alert("Error sending email");
    }
  };

  return (
    <div id="contact_page">
      <Navigation classStyle="contact-menu" />
      <div className="contact-hero">
        <div className="text-side">
          <h1>Contact Us</h1>
          <p>
            Email, Call, or complete the form to reach out to us. If you want to
            book an appointment, click <a href="/book-appointment">here</a>.
          </p>

          <ul className="list-unstyled contacts">
            <li>
              <span className="color-primary">
                <i class="bi bi-telephone-fill"></i>
              </span>
              <a href="tel:0789272904">0789272904</a>
            </li>
            <li>
              <span className="color-primary">
                <i class="bi bi-envelope-fill"></i>
              </span>
              <a href="mailto:info@timelessglam.co.za">
                info@timelessglam.co.za
              </a>
            </li>
          </ul>

          <h4 className="follow-us">Follow Us</h4>
          <div className="social-accounts">
            <a href="https://www.facebook.com/profile.php?id=61556208374191&mibextid=WC7FNe">
              <i class="bi bi-facebook"></i>
            </a>
            <a href="https://www.tiktok.com/@timelessglamhaven">
              <img src={TikTokIcon} alt="TikTok Icon"/>
            </a>
            <a href="https://www.instagram.com/timelessglamhaven">
              <i class="bi bi-instagram"></i>
            </a>
          </div>
        </div>
        <div className="form-side">
          <form onSubmit={handleSubmit} className="shadow">
            <h3>Get in Touch</h3>
            <p>You can reach us anytime.</p>
            <div className="row gy-3">
              <div className="col-sm-12">
                <label htmlFor="name">Full name</label>
                <input
                  type="text"
                  name="fullName"
                  id="fullName"
                  placeholder="Full name (e.g. John Doe)"
                  value={event.fullName}
                  onChange={handleChange}
                />
              </div>
              <div className="col-sm-12">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email"
                  value={event.email}
                  onChange={handleChange}
                />
              </div>
              <div className="col-sm-12">
                <label htmlFor="phone">Contact number</label>
                <input
                  type="tel"
                  name="contactNumber"
                  id="contactNumber"
                  placeholder="Contact number"
                  value={event.contactNumber}
                  onChange={handleChange}
                />
              </div>
              <div className="col-sm-12">
                <label htmlFor="message">Message</label>
                <textarea
                  name="message"
                  id="message"
                  placeholder="How can we help?"
                  value={event.message}
                  onChange={handleChange}
                ></textarea>
              </div>
              <div className="col-sm-12">
                <button type="submit" className="main-btn">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="map-sec">
        <div className="row gx-5 align-items-center">
          <div className="col-sm-8">
            <iframe
              className="map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2057.4822233057666!2d31.01818624791649!3d-29.856807852786204!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1ef7a9c644047901%3A0xccf98a77b98cfed3!2s84%20Dr%20A%20B%20Xuma%20St%2C%20Durban%20Central%2C%20Durban%2C%204001!5e0!3m2!1sen!2sza!4v1708191985562!5m2!1sen!2sza"
              width="100%"
              height="450"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <div className="col-sm-4">
            <div className="map-details">
              <span>Our Location</span>
              <h1>Visit Our Salons</h1>
              <h4>Durban Salon</h4>
              <address>
                84 Dr AB Xuma,
                <br />
                Durban central,
                <br />
                Durban,
                <br />
                Kwa-Zulu Natal,
                <br />
                4001
              </address>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;

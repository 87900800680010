import React, {useState} from 'react'
import Hero from '../components/Hero'
import AboutImg from "../assets/img/about_img3.png"
import Footer from '../components/Footer'
import NailsIcon from '../assets/img/nails-icon2.png'
import EyelashesIcon from '../assets/img/eyelashes-icon2.png'
import HairIcon from '../assets/img/hair-icon2.png'
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';

const Home = () => {
	const settings = {
		infinite: true,
		speed: 3000,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
    	autoplaySpeed: 5000,
	};

    return (
      	<div id="homepage">
			<Hero />
			<div className="about">
				<div className="text-side">
					<h1>Discover Radiant Confidence at <span>Timeless Glam Haven</span> in Durban</h1>
					<p>
						Nestled in the heart of Durban, Timeless Glam Haven is not just a beauty spa; it's an oasis where beauty goes beyond the surface. We firmly believe that true beauty emanates from within, and our mission is to instil confidence, empowerment, and lasting beauty in each of our cherished clients. Join us in the journey to uncover your inner radiance and embrace a beauty that transcends mere appearances.
					</p>
				</div>
				<div className="img-container hide-small">
					<img src={AboutImg} alt="about image" />
				</div>
			</div>
			<div className="services">
				<div className="section-header">
					<span>Services</span>
					<h1>What we provide to <span>our customers</span></h1>
				</div>
				<div className="row gx-5 gy-5 services-list">
					<div className="col-sm-4 service-item">
						<img src={NailsIcon} alt="nails icon" />
						<h4>Nails <span>Services</span></h4>
						<p>Indulge in our comprehensive range of manicure and pedicure services. Click below to view details.</p>
						<a href="/services" className='main-btn'>View More</a>
					</div>
					<div className="col-sm-4 service-item">
						<img src={EyelashesIcon} alt="nails icon" />
						<h4>Eyelash <span>Services</span></h4>
						<p>Elevate your gaze with our array of eyelash services. Click on the link below to view details.</p>
						<a href="/services" className='main-btn'>View More</a>
					</div>
					<div className="col-sm-4 service-item">
						<img src={HairIcon} alt="nails icon" />
						<h4>Hair <span>Services</span></h4>
						<p>Elevate your hair game with our diverse range of services tailored for every hair type.</p>
						<a href="/services" className='main-btn'>View More</a>
					</div>
				</div>
			</div>
			<div className='testimonials'>
				<div className="section-header">
					<span>Testimonials</span>
					<h1>What Our <span>Clients Say</span></h1>
				</div>
				<Slider {...settings}>
					<div>
						<div className='icon-container'>
							<FormatQuoteIcon className='icon'/>
						</div>
						<p>
							I had an amazing experience there yesterday. I didn’t even know what I wanted to do with my hair. The lady who braided my hair was so kind, patient and gentle with my 4C hair. Also got a gorgeous set of nails. I didn’t even break the bank. Please check them out. I am definitely going back.
						</p>
						<h4>~ Unathi Tsalupondo ~</h4>
					</div>
					<div>
						<div className='icon-container'>
							<FormatQuoteIcon className='icon'/>
						</div>
						<p>
						Went in for a soak-off a few days ago. The nail tech was really nice and gentle with my nails. To add; there’s free WIFi just so you can stream and while away time while you do your feminine maintenance. Overall a lovely place with nice staff. Affordable too.
						</p>
						<h4>~ Sikho Mxinwa ~</h4>
					</div>
				</Slider>
			</div>
			<Footer />
      	</div>
    )
}

export default Home;
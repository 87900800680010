import React from "react";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import SomeImage from "../assets/img/slide22.jpg";

const Services = () => {
  return (
    <div id="services_page">
      <Navigation classStyle="in-page-nav" />
      <div className="services-hero">
        <img src={SomeImage} alt="Some Image" />
        <div className="hero-content-container">
          <div className="title">
            <h4>
              <span>Home / </span>Services /
            </h4>
            <h1>Service Offered</h1>
          </div>
        </div>
      </div>

      <div className="services-content">
        <div className="row gy-5">
          {/* <div className="col-sm-12 service-style"> */}
          {/* <h2>Nails Services</h2> */}
          {/* <div className="row gy-3"> */}
          <div className="col-sm-3">
            <h4>Manicure</h4>
            <ul>
              <li>Mans manicure</li>
              <li>Full set with tips</li>
              <li>Acrylic with gel overlay</li>
              <li>French tips</li>
              <li>Nail art</li>
            </ul>
          </div>
          <div className="col-sm-3">
            <h4>Pedicure</h4>
            <ul>
              <li>Gel application</li>
              <li>Tip application</li>
              <li>French tip</li>
              <li>Nail art</li>
            </ul>
          </div>
          {/* </div> */}
          {/* </div>
          <div className="col-sm-12 service-style"> */}
          {/* <h2>Eyelashes Services</h2> */}
          {/* <div className="row gy-3"> */}
          <div className="col-sm-3">
            <h4>Eyelashes Services</h4>
            <ul>
              <li>Eyelash extensions</li>
              <li>Removal</li>
              <li>Refill</li>
              <li>Volume</li>
              <li>Hybrid</li>
              <li>Classic</li>
              <li>Doll eye vs Cat eye</li>
            </ul>
          </div>
          {/* </div> */}
          {/* </div>
          <div className="col-sm-12 service-style"> */}
          {/* <h2>Hair Services</h2> */}
          {/* <div className="row gy-3"> */}
          <div className="col-sm-3">
            <h4>Relaxed Hair Care</h4>
            <ul>
              <li>Wash</li>
              <li>Relax</li>
              <li>Dry perm</li>
              <li>Hair treatment</li>
            </ul>
          </div>
          <div className="col-sm-3">
            <h4>Afro Hair Care</h4>
            <ul>
              <li>Wash & Blow</li>
              <li>Condition</li>
              <li>Silk press</li>
              <li>Corn rows and twist</li>
              <li>Styling</li>
            </ul>
          </div>
          <div className="col-sm-3">
            <h4>Braids Care</h4>
            <ul>
              <li>Undoing</li>
              <li>Wash</li>
              <li>Style</li>
              <li>Scalp treatment</li>
            </ul>
          </div>
          <div className="col-sm-3">
            <h4>Braid Style</h4>
            <ul>
              <li>Wig lines</li>
              <li>Snoopy</li>
              <li>Two line</li>
              <li>Straight back</li>
              <li>Straight up</li>
              <li>Sweet and sour</li>
              <li>Knotless</li>
              <li>Box braids</li>
              <li>Passion twist</li>
            </ul>
          </div>
          {/* </div> */}
        </div>
      </div>
      {/* </div> */}

      <div className="empty-h-25"></div>
      <Footer />
    </div>
  );
};

export default Services;

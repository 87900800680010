import React, { useEffect, useRef } from "react";
import { gsap } from 'gsap';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import SlideImg1 from "../assets/img/slide11.jpg";
import SlideImg2 from "../assets/img/slide22.jpg";
import SlideImg3 from "../assets/img/slide33.jpg";
import Navigation from "./Navigation";

const slidesData = [
    {
      	image: SlideImg3,
      	text: {
        	title: 'Slide 1 Title',
        	description: 'Some description for Slide 1',
      	},
    },
    {
      	image: SlideImg2,
     	text: {
        	title: 'Slide 2 Title',
        	description: 'Some description for Slide 2',
      		},
    },
    {
      	image: SlideImg1,
      	text: {
        	title: 'Slide 3 Title',
        	description: 'Some description for Slide 3',
      	},
    },
];

const Hero = () => {
    const sliderRef = useRef(null);
    useEffect(() => {
        // Initialize GSAP animations when the component mounts
        gsap.from('.hello', {
          	opacity: 0,
          	y: 100,
          	duration: 1.5,
          	delay: 0.5,
          	stagger: 0.5,
        });

    }, []);

    const settings = {
        dots: false,
		fade: true,
        infinite: true,
        speed: 4000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 10000,
		swipeToSlide: true,
    };

    return(
        <div className="hero-part">
          	<Slider ref={sliderRef} {...settings} >
				{slidesData.map((slide, index) => (
					<div key={index} >
						<img src={slide.image} alt={`Slide ${index + 1}`} />
					</div>
				))}
          	</Slider>
          	<div className="text-container">
				<Navigation classStyle="none" />
				<div className="hero-footer">
					<h1>Be the Hero of Your Beauty Journey <span>Unleash Timeless Glam with Confidence!</span></h1>
				</div>
				<div className="right-side">
					<div className="v-line"></div>
					<div className="v-text">Timeless Glam Haven // Follow Us</div>
					<div className="social-accounts">
						<a href="https://www.facebook.com/profile.php?id=61556208374191&mibextid=WC7FNe"><i class="bi bi-facebook"></i></a>
						<a href="https://www.tiktok.com/@timelessglamhaven"><i class="bi bi-tiktok"></i></a>
						<a href="https://www.instagram.com/timelessglamhaven"><i class="bi bi-instagram"></i></a>
					</div>
				</div>
          	</div>
        </div>
    )
}

export default Hero;
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore"
import { getStorage } from "firebase/storage"

const firebaseConfig = {
  apiKey: "AIzaSyCzoo6rJ_uLTVnnDDoioDD-I4MSkWZqZ-A",
  authDomain: "timeless-glam.firebaseapp.com",
  projectId: "timeless-glam",
  storageBucket: "timeless-glam.appspot.com",
  messagingSenderId: "982566936295",
  appId: "1:982566936295:web:6e7f3c7b287d9bcebfcc42",
  measurementId: "G-YWG5WMRXK8"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const storage = getStorage(app)
import React from "react";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import Home from "../pages/Home";
import Booking from "../pages/Booking";
import Contact from "../pages/Contact";
import Services from "../pages/Services";
import Gallery from "../pages/Gallery";

const router = createBrowserRouter(
    createRoutesFromElements([
        <Route path="/" key="homepage" index element={<Home />} />,
        <Route path="/book-appointment" key="Bookings" element={<Booking />} />,
        <Route path="/contact" key="Contact" element={<Contact />} />,
        <Route path="/services" key="Services" element={<Services />} />,
        <Route path="/gallery" key="Gallery" element={<Gallery />} />,
    ])
);
  
export default router;
import React, { useState, useEffect } from 'react';
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import { db, storage } from '../config/firebase'; 
import { getDocs, collection } from "firebase/firestore"
import GalleryPopup from '../components/GalleryPopup';
// import { ref, getDownloadURL } from 'firebase/storage';

const Gallery = () => {
    const [images, setImages] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);

    // const storageRef = ref(storage, "gs://timeless-glam.appspot.com/Gallery/terricks-noah-NvDSfftzr0U-unsplash.jpg");

    // getDownloadURL(storageRef).then((url) => {
    //     console.log("Url: ", url);
    // }).catch((error) => {
    //     console.error("Error:", error);
    // });

    const imagesCollectionRef = collection( db, "gallery");

    useEffect(() => {
        const fetchImagesList = async () => {
            try {
                const data = await getDocs(imagesCollectionRef);
                const filteredData = data.docs.map((doc) => ({
                    ...doc.data(),
                    id: doc.id,
                }))

                setImages(filteredData);
            } catch (error) {
                console.error(error)
            }

        }

        fetchImagesList();
    }, []);

    const handleImageClick = (index) => {
        setSelectedImageIndex(index);
        setShowPopup(true);
        document.body.classList.add('popup-open');
    };

    const handClose = () => {
        setShowPopup(false);
        document.body.classList.remove('popup-open');
    };

    const handleNextImage = () => {
        setSelectedImageIndex((selectedImageIndex + 1) % images.length);
        console.log((selectedImageIndex + 1) % images.length)
    };

    const handlePrevImage = () => {
        setSelectedImageIndex((selectedImageIndex - 1 + images.length) % images.length);
    };
    return(
        <div id="gallery_page">
            <Navigation classStyle="in-page-nav"/>
            <div className="imgs-container">
                {images.map((imageUrl, index) => (
                    <img key={index} src={imageUrl.url } alt={`Image ${index}`} onClick={() => handleImageClick(index)}/>
                ))}
            </div>

            {showPopup && (
                <GalleryPopup imageUrls={images} currentIndex={selectedImageIndex} onClose={handClose} onNext={handleNextImage} onPrev={handlePrevImage}/>
            )}
            <Footer />
        </div>
    )
}

export default Gallery;
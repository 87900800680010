import { useState } from 'react';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from '@mui/icons-material/Close';

const GalleryPopup = ({ imageUrls, currentIndex, onClose, onNext, onPrev }) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(currentIndex);

    const handleNext = () => {
        setCurrentImageIndex((currentImageIndex + 1) % imageUrls.length);
        onNext();
    };
    
    const handlePrev = () => {
        setCurrentImageIndex((currentImageIndex - 1 + imageUrls.length) % imageUrls.length);
        onPrev();
    };

    return(
        <div className="gallery-popup">
            <div className="popup-content">
                <img src={imageUrls[currentImageIndex].url} alt={`Image ${currentImageIndex}`} />
                <div className="nav-btns">
                    <button className="prev-button" onClick={handlePrev}><ArrowBackIosNewIcon /></button>
                    <button className="next-button" onClick={handleNext}><ArrowForwardIosIcon /></button>
                </div>
                <button className="close-btn" onClick={onClose}><CloseIcon /></button>
            </div>
        </div>
    )
}

export default GalleryPopup;
import React, { useState } from "react";
import GlamLogo from "../assets/img/white-logo.png"

const Navigation = ({classStyle}) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
        console.log("menu", isMenuOpen)
    };

    return(
        <div id="navigation" className={classStyle}>
            <a href="/"><img src={GlamLogo} className="menu-brand" alt="Timeless Glam Haven white logo" /></a>
            <button className="menu-toggle" aria-label="Menu" onClick={toggleMenu}>
                <span></span>
                <span></span>
                <span></span>
            </button>
            <ul className={`menu-items ${isMenuOpen ? 'active shadow' : ''}`}>
                <li className="menu-item">
                    <a href="/" className="active-tab">Home</a>
                </li>
                <li className="menu-item">
                    <a href="/services">Services</a>
                </li>
                {/* <li className="menu-item">
                    <a href="/">Pricing</a>
                </li> */}
                <li className="menu-item">
                    <a href="/gallery">Gallery</a>
                </li>
                <li className="menu-item">
                    <a href="/contact">Contact Us</a>
                </li>
                <li className="menu-item call-btn hide-large" >
                    <a href="/book-appointment" className="booking">Book Appointment</a>
                </li>
            </ul>
            <ul className="call-to-action hide-small">
                <li className="menu-item">
                    <a href="/book-appointment" className="booking">Book Appointment</a>
                </li>
            </ul>
        </div>
    )
}

export default Navigation;
import React, { useState } from "react";

const DateTimeSlotPicker = ({
  unavailableSlots,
  selectedDate,
  setSelectedDate,
  selectedTimeSlot,
  setSelectedTimeSlot,
}) => {
  const [showUnavailableSlots, setShowUnavailableSlots] = useState(false);

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
    setSelectedTimeSlot(null);
    setShowUnavailableSlots(true);
  };

  const handleTimeSlotClick = (event, timeSlot) => {
    event.preventDefault();
    setSelectedTimeSlot(timeSlot);
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "short", day: "2-digit" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const generateAvailableSlots = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const availableSlots = [];

    if (selectedDate === null) return availableSlots;

    const selected = new Date(selectedDate);
    if (selected < today) return availableSlots;

    let current = new Date(selected);
    current.setHours(9, 0, 0, 0);

    const end = new Date(selected);
    end.setHours(17, 0, 0, 0);

    while (current <= end) {
      const timeSlot = `${
        current.getHours() < 10 ? "0" : ""
      }${current.getHours()}:00 - ${current.getHours() + 1 < 10 ? "0" : ""}${
        current.getHours() + 1
      }:00`;
      availableSlots.push(timeSlot);
      current.setHours(current.getHours() + 1);
    }

    return availableSlots.filter(
      (slot) =>
        !unavailableSlots.includes(
          `${selected.toISOString().split("T")[0]}T${slot.split(" - ")[0]}:00`
        )
    );
  };

  const availableSlots = generateAvailableSlots();

  return (
    <div className="date-picker-comp">
      <input
        type="date"
        id="datepicker"
        min={new Date().toISOString().split("T")[0]}
        value={selectedDate}
        onChange={handleDateChange}
        required
      />

      {selectedDate && (
        <div className="timeslots-container">
          {availableSlots.map((timeSlot) => (
            <button
              className={
                selectedTimeSlot === timeSlot ? "active-timeslot" : "slot-btn"
              }
              key={timeSlot}
              onClick={(e) => handleTimeSlotClick(e, timeSlot)}
            >
              {timeSlot}
            </button>
          ))}
        </div>
      )}

      {selectedTimeSlot && (
        <div className="booking-for">
          <h2>You're about to book for:</h2>
          <p>
            Date: {formatDate(selectedDate)} - {selectedTimeSlot}
          </p>
        </div>
      )}
    </div>
  );
};

export default DateTimeSlotPicker;
